// Fonts
$default-font: 'urbane-rounded', Roboto, Helvetica, sans-serif;

// Colors
$black: #191c1f;
$alpha-white: rgba(#fff, 0.85);
$pink: #D66BA0;

// Font Weight
$thin: 100;
$light: 300;
$regular: 400;
$medium: 600;
$bold: 700;