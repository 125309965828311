// Reset

*, *::before, *::after {
  box-sizing: border-box !important;
}

img {
  max-width: 100%;
}

html, body, main {
  min-height: 100vh;
  overflow: auto;
}

html {
  -webkit-font-smoothing: antialiased;
  line-height: 1.2;
  font-size: calc(16px + (23 - 16) * (100vw - 640px) / (1439 - 640));
  font-family: $default-font;
}

main {
  color: $black;
  transition: all 0.2s ease-in-out;
  .logo {
    svg {
      fill: $black;
    }
  }
  .theme-color-switcher {
    label {
      span {
        background-color: $black;
        &::before {
          background-color: $alpha-white;
        }
      }
    }
  }
  &.dark-theme {
    color: $alpha-white;
    .logo {
      svg {
        fill: $alpha-white;
      }
    }
    .theme-color-switcher {
      label {
        span {
          background-color: $alpha-white;
          &::before {
            background-color: $black;
          }
        }
      }
    }
    section {
      background-color: $black;
    }
  }
}

header {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 1em;
}

section {
  height: 100vh;
  display: grid;
  place-items: center;
}

.logo {
  width: 2.5em;
  svg {
    max-width: 100%;
  }
}

.theme-color-switcher {
  position: absolute;
  right: 24px;
  bottom: 24px;
  label {
    position: relative;
    input {
      display: none !important;
    }
    span {
      display: block;
      height: 16px;
      width: 32px;
      border-radius: 40px;
      position: relative;
      cursor: pointer;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -5px;
        left: 4px;
        height: 10px;
        width: 10px;
        border-radius: 100%;
        transition: 0.2s ease-in-out;
      }
      &::after {
        content: 'color';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: calc(100% + 8px);
        font-weight: $light;
        font-size: 12px;
      }
    }

    input {
      &:checked {
        & + span {
          &::before {
            transform: translateX(calc(100% + 4px));
          }
        }
      }
    }

  }
}

.intro-content {
  width: 90%;
  max-width: 1020px;
  h1 {
    font-weight: $thin;
    margin: 0;
    font-size: 1.6em;
    line-height: 1.3;
  }
}

.my-links {
  margin: 0.5em 0 0;
  padding: 0;
  list-style: none;
  font-size: 0.75em;
  display: flex;
  li {
    &:not(:last-child) {
      margin-right: 0.6em;
      display: flex;
      align-items: center;
      &::after {
        content: '';
        display: block;
        width: 0.3em;
        height: 0.3em;
        background-color: $pink;
        border-radius: 100%;
        margin-left: 0.6em;
      }
    }
  }
  a {
    text-decoration: none;
    color: $pink;
    position: relative;
    font-weight: $light;
    border-bottom: 1px solid $pink;
    display: block;
    transition: 0.2s;
    &:hover {
      transform: scale(1.02);
    }
    /*&::before {
      content: '';
      position: absolute;
      height: 35%;
      width: 100%;
      background-color: rgba($pink, 0.3);
      bottom: 2px;
      left: 0;
      transition: 0.2s;
    }

    &:hover {
      &::before {
        height: 10px;
        transform: translateY(4px);
      }
    } */

  }
}

@media only screen and (max-width: 767px) {
  .my-links {
    font-size: 0.9em;
  }
}