.block {
  display: block;
}

.dashed {
  text-decoration: underline;
}

.text-light {
  font-weight: $light;
}